<template>
    <div class="order-card-box">
        <div class="goods-card">
            <div class="title">
                <div class="title-shop">
                    <img src="../../assets/images/store.png" alt="" width="20" height="20">
                    <span>{{ orderInfo.shop }}</span>
                <!-- <img src="../../assets/images/enter.png" alt="" width="18" height="18"> -->
                </div>
                <div v-if="orderInfo.status === 'orderPaying'" class="count-down">
                    <van-count-down :time="time" v-show="showCountDown" @finish="finish"/>
                    <span class="status-paying" >待付款</span>
                </div>
                <span class="status-paying" v-else-if="orderInfo.status === 'orderPicking'">待配货</span>
                <span class="status-picking" v-else-if="orderInfo.status === 'orderReceived'">待收货</span>
                <span class="status-picking" v-else-if="orderInfo.status === 'orderRefund'">退款成功</span>
                <span class="status-picking" v-else-if="orderInfo.status === 'orderApplyRefund'">退款中</span>
                <span class="status-cancel" v-else-if="orderInfo.status === 'orderComment'">已完成</span>
                <span class="status-picking" v-else-if="orderInfo.status === 'orderPaid'">已支付</span>
                <span class="status-cancel" v-else-if="orderInfo.status === 'orderCancel'">已取消</span>
                <span class="status-picking" v-else-if="orderInfo.status === 'orderPaidCancel'">退款成功</span>
            </div>
            <div v-if="orderInfo.type !== 'vip' && orderInfo.type !== 'partner'">
                <div class="express-info-picking" v-if="orderInfo.status === 'orderPaying' || orderInfo.status === 'orderPaid' || orderInfo.status === 'orderPicking'">
                    <div class="title">配送信息：</div>
                    <div>72小时内发货</div>
                </div>
                <div class="express-info-picking" v-else-if="orderInfo.status === 'orderReceived'" @click="goExpressPage">
                    <div class="title">配送信息：</div>
                    <div>物流信息</div>
                </div>
            </div>
            <div class="goods-desc" @click="goOrderDetails(orderInfo.oid)" v-for="(list, index) in orderInfo.lists" :key="index">
                <div class="img">
                    <img :src="list.imgUrl" alt="" width="75" height="75">
                </div>
                <div class="card-right">
                    <div class="desc-text">{{ list.title }}</div>
                    <div style="height: 10px;" v-if="list.type === 'partner' || list.type === 'vip'"></div>
                    <div class="type-text" v-else>{{ list.type }}</div>
                    <div class="price">
                        <div><span>￥</span>{{ list.price }}</div>
                        <div style="color:#969699;font-size: 14px;" v-if="orderInfo.status === 'orderRefund' || orderInfo.status === 'orderPaidCancel'">共{{ orderInfo.amount }}件</div>
                    </div>
                </div>
            </div>
            <div v-if="showDetails" class="show-details">
                <div class="ticket">
                    <div>发票信息</div>
                    <div class="ticket-text">不支持自助开票，请联系客服</div>
                </div>
                <div class="price">
                    <div class="total-price">
                        <div>商品总价:</div>
                        <div>￥ {{ orderInfo.totalPrice }}</div>
                    </div>
                    <div class="discount-price">
                        <div>优惠:</div>
                        <div>– ￥ {{ orderInfo.discountPrice }}</div>
                    </div>
                    <div class="express-charge">
                        <div>运费:</div>
                        <div v-if="Number(orderInfo.expressCharge) > 0">￥ {{ orderInfo.expressCharge }}</div>
                        <div v-else>免运费</div>
                    </div>
                    <!-- <div class="call-owner">
                        <div>联系卖家</div>
                        <div class="divider">|</div>
                        <div>投诉卖家</div>
                    </div> -->
                </div>
            </div>
            <div class="price-row" v-if="orderInfo.status !== 'orderRefund' && orderInfo.status !== 'orderPaidCancel' && !showDetails">
                <div class="amount">共{{ orderInfo.lists.length }}件</div>
                <div class="price">
                    <span class="price-grey">总价 ￥{{ orderInfo.totalPrice }}</span>
                    <span class="price-grey">优惠 ￥{{ orderInfo.discountPrice }}</span>
                    <span>实付 ￥{{ orderInfo.finalPayPrice }}</span>
                </div>
            </div>
            <div class="button-group" v-show="showButton">
                <div class="button-pay" v-if="orderInfo.status === 'orderPaying'" @click="pay">立即支付</div>
                <div class="button" v-else-if="orderInfo.status === 'orderPicking' || orderInfo.status === 'orderPaid'" @click="expedited">催包裹</div>
                <div class="button-pay" v-else-if="orderInfo.status === 'orderReceived'" @click="handleReceived">确认收货</div>
                <div class="button" v-else-if="orderInfo.status === 'orderRefund' || orderInfo.status === 'orderPaidCancel'">查看详情</div>
                <div class="button" v-else-if="orderInfo.status === 'orderComment'">再次购买</div>
                <div v-else-if="orderInfo.status === 'orderCancel'" style="display: flex;">
                    <div class="button" @click="deleteOrder">删除订单</div>
                    <div class="button" >再次购买</div>
                </div>
            </div>
        </div>
        <div v-if="showDetails" class="show-details-pay">
            <div class="final-pay-price">
                <div>实付：</div>
                <div>￥ {{ orderInfo.finalPayPrice }}</div>
            </div>
            <div class="pay-desc">
                <div class="row" v-if="orderInfo.status !== 'orderPaying'">支付方式：{{ orderInfo.payment ? orderInfo.payment : '微信' }}</div>
                <div class="row">订单编号：{{ orderInfo.oid }}
                     <!-- <span>复制</span> -->
                </div>
                <div class="row">
                    下单时间：{{ orderInfo.createAt }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'OrderCard',
  components: {
  },
  created(){

  },
  mounted(){
    this.setCountDown()
  },
  updated(){
    this.setCountDown()
  },
  data:()=>{
    return {
        time: 0,
        showCountDown: true
    }
  },
  methods:{
    goOrderDetails(oid){
        this.$router.push({path:'/order-desc', query: { oid: oid, tabId: this.tabId }})
    },
    finish(){
        this.showCountDown = false
    },
    deleteOrder(){
        this.$emit('deleteOrder', this.orderInfo.oid)
    },
    pay(){
        this.$emit('pay', this.orderInfo.oid)
    },
    handleReceived(){
        this.$emit('received', this.orderInfo.oid)
    },
    expedited(){
        this.$emit('expedited', this.orderInfo.oid)
    },
    goExpressPage(){
        this.$emit('goExpressPage', this.orderInfo.oid, this.orderInfo.waybill)
    },
    setCountDown(){
        if(this.orderInfo.status === 'orderPaying'){
            let createAt = moment(this.orderInfo.createAt);
            let curDateTime = moment(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
            let dura = curDateTime.format('x') - createAt.format('x');
            let tempTime = moment.duration(dura);
            this.time = 15 * 60 * 1000 - tempTime.minutes() * 60 * 1000 - tempTime.seconds() * 1000
        }
    }
  },
  props:{
    orderInfo:{
        type: Object,
        default: ()=>{}
    },
    showButton:{
        type: Boolean,
        default: true
    },
    showDetails:{
        type: Boolean,
        default: false
    },
    tabId:{
        type: String,
        default: '0'
    }
  }
}
</script>

<style scoped lang="scss">
.order-card-box {
    .goods-card {
        background: #fff;
        margin: 10px;
        border-radius: 20px;
        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
            font-size: 15px;
            .title-shop {
                display: flex;
                align-items: center;
            }
            .count-down {
                display: flex;
                align-items: baseline;
                span {
                    margin-left: 5px;
                }
            }
            .status-paying {
                font-size: 15px;
                color: #f0061d;
            }
            .status-picking {
                font-size: 15px;
                color: #ffd700;
            }
            .status-cancel {
                font-size: 15px;
                color: #969699
            }
        }
        .express-info {
            display: flex;
            background: #f3f3f3;
            padding: 5px 0;
            margin: 0 15px 20px;
            font-size: 14px;
            border-radius: 5px;
            text-align: left;
            .title {
                padding: 0 10px 0 10px;
            }
        }
        .express-info-picking {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #f3f3f3;
            padding: 5px 10px;
            margin: 0 15px 20px;
            font-size: 14px;
            border-radius: 5px;
            .title {
                padding: 0;
            }
        }
        .goods-desc {
            display: flex;
            position : relative;
            padding: 0 10px 0px;
            .img {
                width: 110px;
            }
            img {
                border-radius: 2px;
            }
            .card-right {
                flex: 1;
                padding: 0 0px 0 10px;
                text-align: left;
                word-break: break-all;
                .desc-text {
                    font-size: 17px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
                .type-text {
                    font-size: 15px;
                    padding: 0;
                }
                .price {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 18px;
                    // font-weight: 600;
                    padding: 5px 15px 20px 0;
                    span {
                        font-size: 16px;
                    }
                }
            }
        }
        .show-details {
            text-align: left;
            margin: 0 15px;
            font-size: 15px;
            .ticket {
                border-top: 1px rgba(0,0,0,0.05) solid;
                border-bottom: 1px rgba(0,0,0,0.05) solid;
                margin: 10px 0;
                padding: 10px 5px;
                .ticket-text {
                    padding: 5px 0;
                    color: #969699;
                }
            }
            .price {
                .total-price {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 5px 5px;
                }
                .discount-price {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 5px 5px;
                }
                .express-charge {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 5px 15px;
                }
                .call-owner {
                    display: flex;
                    justify-content: center;
                    border-top: 1px rgba(0,0,0,0.1) solid;
                    padding: 15px 0;
                    .divider {
                        padding: 0 10px;
                    }
                }
            }
        }
        .price-row {
            display: flex;
            justify-content: space-between;
            margin: 15px 15px 0;
            padding: 15px 0 5px;
            border-top: 1px rgba(0,0,0,0.05) solid;
            .amount {
                font-size: 15px;
                min-width: fit-content;
                color: #969699;
            }
            .price {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                span {
                    padding: 0 5px;
                    font-size: 15px;
                }
                .price-grey {
                    color: #969699;
                }
            }
        }
        .button-group {
            margin: 0 10px;
            display: flex;
            justify-content: flex-end;
            padding-bottom: 5px;
            .button {
                border: 1px rgba(0,0,0,0.2) solid;
                border-radius: 25px;
                padding: 0 15px;
                font-size: 15px;
                margin: 5px 10px;
                height: 30px;
                line-height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            .button-pay {
                border: 1px rgba(0,0,0,0.01) solid;
                border-radius: 25px;
                padding: 0 20px;
                font-size: 15px;
                height: 30px;
                line-height: 30px;
                margin: 5px 10px;
                background: #fc5f10;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }
    .show-details-pay {
        text-align: left;
        background: #fff;
        margin: 10px;
        border-radius: 15px;
        padding: 10px 20px;
        font-size: 15px;
        .final-pay-price {
            display: flex;
            padding: 0 0 10px;
            margin: 5px 0 0;
            border-bottom: 1px rgba(0,0,0,0.1) solid;
        }
        .pay-desc {
            padding: 5px 0 0;
            margin: 0 0 5px;
            .row {
                padding: 5px 0;
            }
        }
    }
}
::v-deep .van-count-down {
    color: #f0061d;
    font-size: 15px;
    line-height: 20px;
}
</style>